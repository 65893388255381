const { event } = require("jquery");

module.exports = ProductTools = {
    renderProductItem : (product, translation, main) => {
        let wrapper = jQuery('<div>').addClass('product-item-wrapper');
        wrapper.attr('data-link', product.view_link)
            .attr('id', 'product_' + product.reference.toLowerCase())
            .attr('data-reference', product.reference);

        if (main) {
            wrapper.addClass('product-main-item');
        }

        let likedClass = (product.liked ? 'product-liked ' : '');
        let loggedClass = (product.logged ? 'user-logged' : '');
        let likedInfo = jQuery('<span>').addClass('info-icon').addClass('like-btn')
            .addClass('product-likes').addClass(likedClass)
            .addClass(loggedClass).attr('id', 'product_like_' + product.reference)
            .append(
                jQuery('<i>').addClass('fas').addClass('fa-heart').addClass('like-btn')
            ).append(
                jQuery('<span>').addClass('info-counter').addClass('like-btn')
                    .addClass('product-likes-count').text(product.likes)
            );
        
        if (product.logged) {
            likedInfo.attr('data-link', product.like_link);
        }

        wrapper
            .append(
                jQuery('<div>').addClass('product-item')
                    .append(
                        jQuery('<img>').addClass('product-image')
                            .attr('src', product.picture)
                            .attr('alt', product.title)
                    ).append(
                        jQuery('<div>').addClass('product-desc')
                            .addClass('reveal-anim-enter')
                            .append(
                                jQuery('<div>').addClass('product-desc-layout')
                                    .append(
                                        jQuery('<div>').addClass('product-title').text(product.title)
                                    ).append(
                                        jQuery('<div>').addClass('product-info').addClass('item-info')
                                            .append(
                                                jQuery('<span>')
                                                    .append(
                                                        jQuery('<span>').addClass('info-icon')
                                                            .addClass('item-info')
                                                            .append(
                                                                jQuery('<i>').addClass('fa-regular')
                                                                    .addClass('fa-comments').addClass('item-info')
                                                            )
                                                    ).append(
                                                        jQuery('<span>').addClass('info-counter').addClass('item-icon')
                                                            .text(product.comments)
                                                    )
                                            ).append(likedInfo).append(
                                                jQuery('<span>').attr('id', 'product_download_' + product.reference)
                                                    .addClass('info-icon').addClass('product-download')
                                                    .addClass('item-info')
                                                    .append(
                                                        jQuery('<i>').addClass('fa-solid')
                                                            .addClass('fa-download').addClass('item-info')
                                                    ).append(
                                                        jQuery('<span>').addClass('info-counter')
                                                            .addClass('item-info').text(product.downloads)
                                                    )
                                            )
                                    )
                            )
                    )
            );

        wrapper.on('click', event => {
            event.stopImmediatePropagation();
            event.stopPropagation();
            event.preventDefault();

            let eventTarget = jQuery(event.target);
            if (!eventTarget.hasClass('like-btn')) {
                window.location.href = product.view_link;
            }
        });

        likedInfo.on('click', event => {
            event.stopImmediatePropagation();
            event.stopPropagation();
            event.preventDefault();

            let eventTarget = jQuery(event.target);
            console.log('like btn clicked')
            console.log(eventTarget);
        });
        
        /*if (wrapper !== undefined) {
            let reference = wrapper.data('reference');
            let productViewLink = wrapper.data('link');
            let productLikeIcon = jQuery('#product_like_' + reference);

            let items = wrapper.find('.product-likes');

            if (items !== undefined && items.length > 0) {
                items.each((index, elt) => {
                    let element = jQuery(elt);

                    if (element.hasClass('user-logged')) {
                        element.on('click', event => {
                            event.preventDefault();
                            event.stopPropagation();
                            event.stopImmediatePropagation();

                            let targetUrl = element.data('link');
                            let productId = element.data('product-id');
                            let token = element.data('token');

                            if (targetUrl !== undefined) {
                                jQuery.ajax(targetUrl, {
                                    method : 'POST',
                                    dataType : "json",
                                    data : JSON.stringify({
                                        'product_id' : productId,
                                        'token' : token
                                    }),
                                    success : (response, status) => {
                                        if (status === 'success') {
                                            let counter = jQuery('.product-likes-count', element);

                                            counter.text(response.count);

                                            if (element.hasClass('product-liked')) {
                                                element.removeClass('product-liked');
                                            } else {
                                                element.addClass('product-liked');
                                            }
                                        }
                                    },
                                    error : function (result, status, error) {}
                                });
                            }
                        });
                    }
                });
            }* /
           console.log(reference);
           console.log(productLikeIcon);
        }*/

        return wrapper;
    }
}
