jQuery(document).ready(() => {
    let appointmentForm = jQuery('#appointment_request_creation_form');

    if (appointmentForm !== undefined) {
        let datepickerFields = jQuery('.appointment-datepicker');

        if (datepickerFields !== undefined) {
            datepickerFields.each((index, field) => {
                jQuery(field).datepicker({
                    format: 'yyyy-MM-dd'
                });
            });
        }
            
        appointmentForm.on('submit', (event) => {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();

            let appointmentData = JeproFormTools.getJsonData(appointmentForm);
            let targetUrl = appointmentForm.attr('action');

            jQuery.ajax(targetUrl, {
                method : "POST",
                credentials: 'same-origin',
                dataType : "json",
                data: JSON.stringify(appointmentData),
                success: (response, status) => {
                    if (status == 'success') {
                        JeproTools.displayModalNotification('site');
                        let messageWrapper = jQuery('<div>').addClass('alert')
                            .addClass('alert-' + response.type)
                            .attr('role', 'alert')
                            .append(
                                jQuery('<h5>').addClass('alert-title')
                                    .text(response.title)
                                ).append(
                                    jQuery('<p>').addClass('alert-description')
                                        .text(response.message)
                                );
                    }
            
                },
                error: (response, status, error) => {}
            });
        });
    }
});
