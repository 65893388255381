let renderEmployee = (employee, translation) => {
    let wrapper = jQuery('<div>').addClass('agency-team-member');

    wrapper
        .append(
            jQuery('<div>').addClass('team-member-thumb')
                .append(
                    jQuery('<img>')
                        .attr('src', employee.thumb)
                        .attr('alt', employee.first_name.slice(0, 1).toUpperCase() + employee.last_name.slice(0, 1).toUpperCase())
                )
        ).append(
            jQuery('<h5>').addClass('team-member-name')
                .text(employee.first_name + ' ' + employee.last_name.toUpperCase())
        ).append(
            jQuery('<p>').addClass('team-appointment').text(employee.appointment)
        );

    if (employee.networks !== undefined) {
        let socialNetworkWrapper = jQuery('<div>').addClass('team-social-network');

        let networks = Object.entries(employee.networks);

        networks.map((entry, index) => {
            socialNetworkWrapper.append(
                jQuery('<a>').addClass('social-link')
                    .attr('href', entry[1].link).attr('target', '_blank')
                    .append(
                        jQuery('<div>').addClass('social-icon')
                            .append(
                                jQuery('<i>').addClass(entry[1].icon)
                            )
                    )
            );
        });
        wrapper.append(socialNetworkWrapper);
    }

    wrapper
        .append(
            jQuery('<div>').addClass('team-member-read-more')
                .append(
                    jQuery('<a>').addClass('btn').attr('href', employee.path)
                        .text(translation.read_more)
                )
        );
    return wrapper;
};

jQuery(document).ready(() => {
    let employeeSection = $('#agency_employee_section');

    if (employeeSection !== undefined) {
        let triggered = false;
        let windowObject = jQuery(window);

        let wrapperPosition = employeeSection.offset().top + employeeSection.outerHeight();

        windowObject.on('scroll', () => {
            let windowHeight = windowObject.scrollTop() + windowObject.height();
           
            if ((windowHeight > wrapperPosition) && !triggered) {
                triggered = true;
                
                let targetLink = employeeSection.data('link');

                let employeeContainer = jQuery('#employee_team_container');

                if (targetLink != '') {
                    jQuery.ajax(targetLink, {
                        type : 'GET',
                        dataType : 'json',
                        success: (response, status) => {
                            employeeContainer.empty();

                            let containerWidth = employeeContainer.parent().width();
                            let cellWidth = 0;
                            if (containerWidth >= 992) {
                                cellWidth = (containerWidth - 64) / 5;
                            } else if (containerWidth >= 768 && containerWidth < 992) {
                                cellWidth = (containerWidth - 48) / 4;
                            } else if (containerWidth >= 576 && containerWidth < 768) {
                                cellWidth = (containerWidth - 32) / 3;
                            } else {
                                cellWidth = (containerWidth - 16) / 2;
                            }

                            if (status === 'success') {
                                if (response.employees !== undefined && response.employees.length > 0) {
                                    response.employees.forEach(element => {
                                        let wrapper = renderEmployee(element, response.translation);
                                        wrapper.css('min-width', Math.floor(cellWidth) + 'px');
                                        wrapper.css('max-width', Math.floor(cellWidth) + 'px');
                                        wrapper.css('width', Math.floor(cellWidth) + 'px');
                                        employeeContainer.append(wrapper);
                                        employeeSection.removeClass('d-none')
                                    });
                                }
                            }
                        },
                        error: (response, status, error) => {}
                    });
                }
            }
        });
    }
});
