//Home page style
import '../../sass/homepage/homepage.scss';

require('../../../tools/js/appear.js');
require('../../../tools/js/product.js');
require('aos');
require('./appointment.js');
require('../utilities/team-member.js')
require('../utilities/last-work.js');
/*
$(document).ready(() => {
    

    /*
        if (!triggered) {
            triggered = true;
            employeeSection.on('click', event => {
                event.preventDefault();
                event.stopPropagation();
                event.stopImmediatePropagation();

            });
            //employeeSection.appear(() => {});
            /*appear({
                init : () => {

                },
                appear : elt => {

                }
            });* /
        }
    }*/

    /*AOS.init({
        duration: 800,
        easing: 'slide',
        once: true,
        mirror: false
    });* /
});*/