
jQuery(document).ready(() => {
    let lastRealisationSection = jQuery('#product_latest_work_wrapper');

    if (lastRealisationSection !== undefined) {
        let triggered = false;
        let windowObject = jQuery(window);

        let wrapperPosition = lastRealisationSection.offset().top + lastRealisationSection.outerHeight();

        windowObject.on('scroll', () => {
            let windowHeight = windowObject.scrollTop() + windowObject.height();

            if ((windowHeight > wrapperPosition) && !triggered) {
                triggered = true;

                let targetLink = lastRealisationSection.data('link');
                let lastRealisationContainer = jQuery('#product_latest_work_container');

                if (targetLink != null) {
                    jQuery.ajax(targetLink, {
                        type : 'POST',
                        dataType : 'json',
                        success : (response, status) => {
                            if (status === 'success') {
                                if (response.products !== undefined && response.products.length > 0) {
                                    let value = Math.floor(Math.random() * 7);
                                    response.products.forEach((element, index) => {
                                        let wrapper = ProductTools.renderProductItem(element, response.translation, (index === value));
                                        lastRealisationContainer.append(wrapper);
                                    });
                                }
                            }
                        },
                        error: (response, status, error) => {}
                    });
                }
            }
        });
    }
});